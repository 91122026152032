@import "./global";

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  outline: none;
  margin: 0;
  padding: 0;
}

.fa,
.fas {
  font-family: "Font Awesome 5 Free";
}

html,
body {
  background-color: #f9f9f9;
  font-size: 16px;

  @include media(tab-port) {
    font-size: 15px;
  }

  @include media(phone) {
    font-size: 14px;
  }
}

.k-principal {
  background-color: green;
}

.k-container {
  margin: {
    right: auto;
    left: auto;
  }
  width: 70%;
  max-width: 55rem;
  z-index: 1;
  position: relative;

  @include media(tab-port) {
    width: 80%;
    padding: 0 0.5rem;
  }
  @include media(phone) {
    width: 100%;
  }
}

.principal {
  background: #fff;
  margin: {
    top: 3rem;
    bottom: 2rem;
  }
  padding: 3.7rem;
  border-radius: 0.5rem;
  box-shadow: rgba(50, 50, 50, 0.1) 2px 2px 30px -12px;

  @include media(tab-port) {
    margin-top: 2rem;
    padding: 2rem;
    box-shadow: rgba(50, 50, 50, 0) 2px 2px 30px -12px;
  }

  @include media(phone) {
    margin-top: 1.5rem;
    padding: 1.5rem 1rem;
  }

  .titulo {
    @include K-txt2;
    color: $k-cinza-700;
  }
}

.navigate-button {
  display: flex;
  justify-content: space-between;
  margin: 2.7rem 0 0;

  button {
    &:only-child {
      margin-left: auto;

      @include media(phone) {
        margin-left: initial;
        width: 100%;
      }
    }
  }

  .botaoContinuar {
    @include K-botao;
    background-color: $k-azul-500;
  }

  .botaoVoltar {
    @include K-botao;
    background-color: $k-cinza-300;
  }
}

.btnInativo {
  opacity: 0.5;
  pointer-events: none;
}

.btnCarregando {
  pointer-events: none;

  i {
    animation: spin-animation 1s infinite;
    display: inline-block;
  }

  span {
    display: none;
  }
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

// Erro no input
.iptTxtErro {
  input {
    border-bottom-color: red !important;
  }
}

.font-weight-bold {
  font-weight: 700 !important;
}

.version {
  font-size: 0.7rem;
  text-align: right;
  color: #aaa;
  margin: 0;
  position: fixed;
  bottom: 0.3rem;
  left: 1rem;
  z-index: 0;
}
